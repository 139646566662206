import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Lines from "./components/lines/Lines";
import Racing from "./components/racing/Racing";
import Result from './components/results/Result';
import { socket } from "./io";
import './App.css';
import Bonos from './components/bonos/Bonos'
import Admin from "./components/admin/Admin";
import Dashboad from "./components/dashboard/Dashboard";
import Bet from "./components/bet/bet";
import Login from "./components/users/login-session";
import { useTranslation } from 'react-i18next';
function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  socket.on('closed', (data)=>{
    console.log('closed')
    localStorage.clear()
    document.location.href = '/'
  })
  if(!localStorage.getItem('user')){
    return window.location.href = '/login'
  }else{
    if(localStorage.getItem('level')=='1' || localStorage.getItem('level')=='2' ){
      return(<Admin/>)
    }else if(localStorage.getItem('level')=='3'){
      return(
      <Dashboad/>)
    }else if(localStorage.getItem('level')=='4'){
      return(
        <Bet/>
)
    }else{
 return(
 <Login/>)
    }
  }

}

export default App;