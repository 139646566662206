import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {PenFill} from 'react-bootstrap-icons'
import { headers, url } from '../../utils/const'

function User() {
  const [show, setShow] = useState(false);
  const [showModalPoint, setShowModalPoint] = useState(false);
  const [user, setUser] = useState({
    name: "",
    userName: "",
    level: "4",
    phone: "",
    password: "",
    confirmePassword: "",
  });
  /* if(localStorage.getItem('level')=='5'){
        setUser({        
            name: '',
            user: '',
            level: '4',
            phone: '',
            password: '',
            confirmePassword: ''})
    }*/
  const [points, setPoints] = useState([]);
  const [point, setPoint] = useState("");
  const [userAsignPoint, setuserAsignPoint] = useState("");
  const [listUser, setListUser] = useState([]);
  // useEfect para cargar los usuarios al entrar al componente
  useEffect(() => {
    if (
      localStorage.getItem("sessionId") &&
      localStorage.getItem("level") &&
      (localStorage.getItem("level") === "1" ||
        localStorage.getItem("level") === "2")
    ) {
      fetch(`${url}/user`, {
        method: "get",
        headers: headers,
      })
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setListUser(() => data);
            console.log(data);
          }
        });
    }
  }, []);

  // Detecta los cambios hechos en los imputs
  const Onchange = (e) => {
    if (e.target.name === "name")
      setUser((user) => {
        return { ...user, name: e.target.value };
      });
    if (e.target.name === "user")
      setUser((user) => {
        return { ...user, userName: e.target.value };
      });
    if (e.target.name === "level")
      setUser((user) => {
        return { ...user, level: e.target.value };
      });
    if (e.target.name === "phone")
      setUser((user) => {
        return { ...user, phone: e.target.value };
      });
    if (e.target.name === "password")
      setUser((user) => {
        return { ...user, password: e.target.value };
      });
    if (e.target.name === "confirmePassword")
      setUser((user) => {
        return { ...user, confirmePassword: e.target.value };
      });
  };

  const OnChangePoint = (e) => {
    setPoint(() => e.target.value);
  };
  const AsignPoint = () => {
    fetch(`${url}/user`, {
      method: "put",
      headers: headers,
      body: JSON.stringify({ point, userAsign: userAsignPoint }),
    })
      .then((res) => res.json())
      .then((data) => {
        setShowModalPoint(false);
      });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //
  const handleShowModalPoint = (level, userPoint) => {
    if (level !== "4") return;
    fetch(`${url}/point`, {
      method: "get",
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setPoints(() =>
            data.map((z) => {
              return { id: z._id, name: z.name };
            })
          );
          setuserAsignPoint(() => userPoint);
          setPoint(() => data[0].name);
        }
      });
    setShowModalPoint((value) => true);
  };
  const handleCloseModalPoint = () => setShowModalPoint(false);
  const Createuser = () => {
    fetch(`${url}/user`, {
      method: "post",
      headers: headers,
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setListUser(() => data);
        }
      });
  };
  /** Disable points */
  const DisaableUser = useCallback(async (code, state) => {
    console.log(code, state)
    const res = await fetch(`${url}/user/active`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ code, state }),
    });
    const data = await res.json();
    console.log(data);
  },[]);
  return (
    <div>
      <div className="p-2 m-2 justify-content-end row col-12 card-header">
        <div className="col-4">
          <button onClick={handleShow} className="btn btn-primary pt-2 mt-2">
            Crear Nuevo Usuario
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Usuario</th>
              <th>Nivel</th>
              <th>Consorcio</th>
              <th>Contacto</th>
              <th>Puntos</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {listUser.map((z, index) => (
              <tr key={(1 + index).toString()}>
                <td>{index + 1}</td>
                <td>{z.name}</td>
                <td>{z.user}</td>
                <td>{z.level}</td>
                <td>{z.customer ? z.customer : ""}</td>
                <td>{z.phone}</td>
                <td onClick={() => handleShowModalPoint(z.level, z.user)}>
                  {z.level === "4" && z.point ? z.point.name : ""}{" "}
                  <PenFill hidden={z.level != 4} />
                </td>
                <td>
                  {" "}
                  <td>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={z.activated}
                        onChange={() => DisaableUser(z._id, z.activaded)}
                      />
                    </div>
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={handleClose} className="bg-info">
        <Modal.Header closeButton>
          <Modal.Title>Crear Nuevo Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 row form-group">
            <div className="col-6 form-group">
              <div className="col-12">
                <label>Nombre:</label>
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={user.name}
                  onChange={Onchange}
                ></input>
              </div>
            </div>
            <div className="col-6 form-group">
              <div className="col-12">
                <label>Usuario:</label>
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="user"
                  value={user.user}
                  onChange={Onchange}
                ></input>
              </div>
            </div>
            <div className="col-6 form-group">
              <div className="col-12">
                <label>Telefono</label>
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={user.phone}
                  onChange={Onchange}
                ></input>
              </div>
            </div>
            <div className="for-group col-6">
              <div className="col-12">
                <label>Seleccione el Rol</label>
              </div>
              <div className="col-12">
                <select
                  className="form-select"
                  name="level"
                  value={user.level}
                  onChange={Onchange}
                >
                  <option value="1">Admin</option>
                  <option value="2">Consorcio</option>
                  <option value="3">Soporte Tecnico</option>
                  <option value="4">Punto de Venta</option>
                </select>
              </div>
            </div>
            <div className="col-6 form-group">
              <div className="col-12">
                <label>Password:</label>
              </div>
              <div className="col-12">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={user.password}
                  onChange={Onchange}
                ></input>
              </div>
            </div>
            <div className="col-6 form-group">
              <div className="col-12">
                <label>Repetir Password:</label>
              </div>
              <div className="col-12">
                <input
                  type="password"
                  className="form-control"
                  name="confirmePassword"
                  value={user.confirmePassword}
                  onChange={Onchange}
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={Createuser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalPoint}
        onHide={handleClose}
        size="lg"
        className="bg-info"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear Nuevo Punto</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="col-12 row justify-content-center">
              <div className="col-10 form-group">
                <div className="col-12">
                  <label>{`Asinar Punto De Venta a: ${userAsignPoint}`}</label>
                </div>
                <div className="col-12">
                  <label>Seleccione Un Punto De Venta</label>
                  <select
                    className="form-control"
                    value={point}
                    onChange={OnChangePoint}
                  >
                    {points.map((z) => (
                      <option key={z.id}>{z.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={handleCloseModalPoint}>
              Close
            </button>
            <button type="button" onClick={AsignPoint}>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default User