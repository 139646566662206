import React, { useEffect, useState } from 'react'
import './Login.css'
import { headers, url } from '../../utils/const'
import { useTranslation } from 'react-i18next';
function Login() { 
  const { t, i18n } = useTranslation();
    const [userName, setUser] = useState();
    const [password, setPassword] = useState(); // para nivel 5 carga el numero de telefono
    const [level, setLevel] = useState('5')
    const [client, setClient] = useState();
    const [point, setPoint] = useState();
    const [code, setCode] = useState();
    const [newPlayer, setNewPlayer] =useState(false)

    /*  <ReactWhatsapp number="+18293338488" message="Hello World!!!"  value="What"/>*/

    useEffect(() => {
        if (localStorage.getItem('sessionId')) {
            if (localStorage.getItem('level') == '1' || localStorage.getItem('level') == '2') window.location.href = '/admin'
            if (localStorage.getItem('level') == '4') window.location.href = '/bet';
            if (localStorage.getItem('level') == '3') window.location.href = '/line';
            if (localStorage.getItem('level') == '5') window.location.href = "/admin-player";
        }

    }, [])
    /**detectar cambio en input */
    const ToChange = (e) => {
        if (e.target.name === 'userName') setUser(e.target.value)
        if (e.target.name === 'password') setPassword(e.target.value)
        if (e.target.name === 'level') setLevel(e.target.value)
        if (e.target.name === 'client') setClient(e.target.value)
        if (e.target.name === 'point') setPoint(e.target.value)
        if (e.target.name === 'code') setCode(e.target.value)    
    }

    /**Login */
    const ToLogin = () => {
        let body = {}
        if(level ==='5' && newPlayer){
            body ={name: userName, username:password, password, level, point, client, code, document: {type:'phone', number:password}}
        } else{
            body ={ username:userName, password, level}
        }
        console.log(body)
        fetch(`${url}/login`, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(body)
        }).then(res => res.json()).then(data => {
            console.log(data)
          if (data && (data.user || data.code )&& data.name && data.level) {
                localStorage.setItem('sessionId', data.sessionId); 
                localStorage.setItem('user', data.user || data.code);
                localStorage.setItem('name', data.name);
                localStorage.setItem('level', data.level);
                localStorage.setItem('point', data.point);
                localStorage.setItem('customer', data.company);
                document.cookie =`sessionId=${data.sessionId} path=/`
                if (localStorage.getItem('user') || localStorage.getItem('sessionId')) window.location.href = '/';
            } else {
            }
        }).catch(error => console.log(error))
    }
    return (
        <div className='container justify-content-center row'>

            <div className='row col-md-6 col-sm-12 col-xl-4 card justify-content-center'>
                <div className='card-header text-center' ><label className='etiquet'>Login</label></div>
                <div className='card-body justify-content-center'>
                    <div className='row col-12 justify-content-end p-0 m-0'>
                        <div className='col-sm-8 col-xl-4 col-md-4 p-0 m-0'><button className='btn btn-secondary form-control'  onTouchStart={()=> setNewPlayer(value=> !value)} disabled={level !=='5'}>Registrar Nuevo Jugador</button></div>
                    </div>
                <div className='form-group col-sm-12 col-xl-8 com-md-4 row justify-content-center'>
                        <div className='col-12'><label>Seleccione el Rol</label></div>
                        <div className='col-12'><select className='form-select' name='level' value={level} onChange={ToChange}>
                            <option value="5" selected>{t('player')}</option>
                            <option value="4">Punto de Venta</option>
                            <option value="2">Consorcio o Grupo</option>
                            <option value="3">Soporte Tecnico</option>
                            <option value="1">Admin</option>
                            <option value="6">Agente</option>

                            
                        </select>
                        </div>
                    </div>
                    {level==='5' && newPlayer? <div className='col-12 row p-0 m-0 justify-content-center'>
                     <div className='form-group col-sm-12'>
                        <div className='col-12'><label>Nombre Jugador</label></div>
                        <div className='col-12'><input type="text" name='userName' className='form-control' value={userName} onChange={ToChange} /></div>
                    </div>
                    <div className='form-group col-12'>
                        <div className='col-12'><label>Celular</label></div>
                        <div className='col-12'><input type="text" name='password' className='form-control' value={password} onChange={ToChange} /></div>
                    </div>
                    <div className='form-group col-12'>
                        <div className='col-12'><label>Consorcio</label></div>
                        <div className='col-12'><input type="text" name='client' className='form-control' value={client} onChange={ToChange} /></div>
                    </div>
                    <div className='form-group col-12'>
                        <div className='col-12'><label>Punto de Venta</label></div>
                        <div className='col-12'><input type="text" name='point' className='form-control' value={point} onChange={ToChange} /></div>
                    </div>
                    <div>
                    <div className='form-group col-12'>
                        <div className='col-12'><label>Codigo</label></div>
                        <div className='col-12'><input type="text" name='code' placeholder='Escriba un Código de 4 Digitos' className='form-control' value={code} onChange={ToChange} /></div>
                    </div>                      
                    </div> 
                    </div>
                   
                    :<div>
                    <div className='form-group col-12'>
                        <div className='col-12'><label>{level==='5'? 'Celular':'Usuario'}</label></div>
                        <div className='col-12'><input type="text" name='userName' className='form-control' value={userName} onChange={ToChange} /></div>
                    </div>
                    <div className='form-group col-12'>
                        <div className='col-12'><label>Password</label></div>
                        <div className='col-12'><input type="password" name='password' className='form-control' value={password} onChange={ToChange} /></div>
                    </div>
                </div>}

                <div className=' row col-12 pt-3 pr-0 mr-0 justify-content-center'>
                    <div className='col-5 pr-0 mr-0'><button className='btn btn-primary form-control' onClick={ToLogin}>Login</button></div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Login