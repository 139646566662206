// export const url ='http://localhost:3008'
//export const url ='http://192.168.1.2:3008'
export const url ='https://dogs8.corriendogs.com' 


export const headers = {
    'content-type': 'application/json',
    credentials: 'include',
   // 'Cookie': `sessionId=${document.cookie.replace(/(?:(?:^|.*;\s*)tuCookieNombre\s*\=\s*([^;]*).*$)|^.*$/, "$1")}`,
    'authorization': `${localStorage.getItem('sessionId')}`
}

export const widthCanvas=window.innerWidth*0.99
export const heightCanvas=window.innerHeight*0.99

export const sizeRef = ()=>{
    const w = widthCanvas;
    const h = heightCanvas;
    return(w<h? w:h)
}

export const lang = 'EN' // 'ES', 'IT', 'PO'